.Social-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.contentDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 80px;
}

.Twitter {
  width: 40vh;
  height: 10vh;
  background-color: black;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.Twitter:hover {
  transform: scale(1.05);
}

.Xlogo {
  background-image: url("./Xaccount.png");
  background-size: cover;
  height: 10vh;
  width: 30vh;
}

.TikTok {
  width: 40vh;
  height: 10vh;
  background-color: white;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.TikTok:hover {
  transform: scale(1.05);
}

.Tlogo {
  background-image: url("./TikTok.png");
  background-size: cover;
  height: 10vh;
  width: 30vh;
}

.Youtube {
  width: 40vh;
  height: 10vh;
  background-color: white;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.Youtube:hover {
  transform: scale(1.05);
}

.Ylogo {
  background-image: url("./YouTube.png");
  background-size: cover;
  height: 10vh;
  width: 30vh;
}

.Instagram {
  width: 40vh;
  height: 10vh;
  background-color: black;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.Instagram:hover {
  transform: scale(1.05);
}

.Instagramlogo {
  background-image: url("./InstaLogo.png");
  background-size: cover;
  height: 10vh;
  width: 30vh;
}

.discord {
  width: 40vh;
  height: 10vh;
  background-color: white;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.discord:hover {
  transform: scale(1.05);
}

.Dlogo {
  background-image: url("./Discord.png");
  background-size: cover;
  height: 10vh;
  width: 30vh;
}

.telegram {
  width: 40vh;
  height: 10vh;
  background-color: white;
  margin: 2.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.telegram:hover {
  transform: scale(1.05);
}

.Telogo {
  background-image: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.Qp-how0t0P5Na-u4cv-WGQHaG4%26pid%3DApi&f=1&ipt=42ffba95790f7ce45fdcd3ca8ffbaa8cfc264d9a6c06c09527b1efd101be5ddf&ipo=images");
  background-size: cover;
  height: 8vh;
  width: 8vh;
}
