@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,1000&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.containerColor {
  background-color: black;
}

.logoDiv {
  background-position: center;
  background-image: url("BadInvestmentCoin.svg");
  min-height: 50px;
  min-width: 50px;
}

.menuButton {
  min-height: 50px;
  min-width: 50px;
  color: #d1d1d1;
}

a {
  text-decoration: none;
  color: inherit;
}

.demo1 {
  color: white;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.demo2 {
  color: white;
  margin-right: 5px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}
