@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@100&family=Roboto&family=Source+Code+Pro&display=swap");

.Error-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AboutError {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.AlexanderRadio {
  border-radius: 50%;
  height: 60vh;
  width: 60vh;

  animation: rotate 5s infinite linear;
}
.RadioShadow {
  border-radius: 50%;
  height: 60vh;
  width: 60vh;
  margin: 5vh;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
  background-color: black;
}

@media (max-width: 1000px) {
  .AlexanderRadio,
  .RadioShadow {
    height: 70vw;
    width: 70vw;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.The404 {
  margin: 2.5vh;
  margin-top: 0;
  font-size: 5vh;
  color: #d1d1d1;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

.Explain404 {
  margin: 2.5vh;
  margin-top: 0vh;
  font-size: 5vh;
  color: #d1d1d1;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

@media (max-width: 1000px) {
  .The404 {
    font-size: 5vw;
  }
}

@media (max-width: 1000px) {
  .Explain404 {
    font-size: 5vw;
  }
}
