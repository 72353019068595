@import url("https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap");

.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 120vh;
  position: absolute;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ShibaDown {
  background-image: url("./CryingShiba.png");
  background-size: cover;
  min-width: 1200px;
  min-height: 400px;
  max-width: 1200px;
  max-height: 400px;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
}

.Intro {
  background-image: url("./DownBackground.png");
  background-size: cover;
  display: flex;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  margin-bottom: 3vh;
  min-width: 1200px;
  min-height: 600px;
  max-width: 1200px;
  max-height: 600px;
  margin-top: 150px;
}
.bshib {
  border-radius: 50%;
  height: 500px;
  width: 500px;

  animation: rotate 20s infinite linear;
}
.shibShadow {
  border-radius: 50%;
  height: 500px;
  width: 500px;
  margin: 5vh;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
  background-color: black;
}

.typerDiv {
  font-size: 50px;
  padding: 50px;
  font-family: "Reddit Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin-top: 50px;
}

.founderDiv {
  min-width: 1200px;
  min-height: 700px;
  max-width: 1200px;
  max-height: 700px;
  background-color: #494c51;
  margin: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

.founderImage {
  background-position: center;
  background-size: cover;
  background-image: url("./Alexander.png");
  min-width: 400px;
  min-height: 600px;
  max-width: 400px;
  max-height: 600px;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
  margin-left: 50px;
}

.founderText {
  min-width: 640px;
  min-height: 600px;
  max-width: 640px;
  max-height: 600px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
  text-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

.founderTitle {
  font-size: 35px;
}

.founderDescription {
  font-size: 25px;
  padding: 15px;
  padding-top: 0;
  text-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

.FounderLink {
  background-color: #545454;
  font-size: 35px;
  padding: 10px;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

@media screen and (max-width: 1290px) {
  .Intro {
    min-width: 900px;
    min-height: 450px;
    max-width: 900px;
    max-height: 450px;
    margin-top: 112.5px;
  }
  .bshib,
  .shibShadow {
    height: 375px;
    width: 375px;
  }
  .shibShadow {
    margin: 37.5px;
  }
  .typerDiv {
    font-size: 37.5px;
    padding: 37.5px;
    margin-top: 37.5px;
  }
  .ShibaDown {
    min-width: 900px;
    min-height: 300px;
  }
  .founderDiv {
    min-width: 900px;
    min-height: 525px;
    max-width: 900px;
    max-height: 525px;
    margin: 37.5px;
  }

  .founderImage {
    min-width: 300px;
    min-height: 450px;
    max-width: 300px;
    max-height: 450px;
    margin-left: 37.5px;
  }

  .founderText {
    min-width: 480px;
    min-height: 450px;
    max-width: 480px;
    max-height: 450px;
  }

  .founderTitle {
    font-size: 26.26px;
  }

  .founderDescription {
    font-size: 18.75px;
    padding: 11.25px;
  }
  .FounderLink {
    font-size: 26.26px;
    padding: 7.5px;
  }
}

@media screen and (max-width: 950px) {
  .Intro {
    min-width: 700px;
    min-height: 350px;
    max-width: 700px;
    max-height: 350px;
    margin-top: 100px;
  }
  .bshib,
  .shibShadow {
    height: 291.66px;
    width: 291.66px;
  }
  .shibShadow {
    margin: 29.166px;
  }
  .typerDiv {
    font-size: 29.166px;
    padding: 29.166px;
    margin-top: 29.166px;
  }
  .ShibaDown {
    min-width: 700px;
    min-height: 233.33px;
  }
  .founderDiv {
    min-width: 700px;
    min-height: 408.345px;
    max-width: 700px;
    max-height: 408.345px;
    margin: 29.16px;
  }
  .founderImage {
    min-width: 233.33px;
    min-height: 350px;
    max-width: 233.33px;
    max-height: 350px;
    margin-left: 29.16px;
  }

  .founderText {
    min-width: 373.344px;
    min-height: 350px;
    max-width: 373.344px;
    max-height: 350px;
  }

  .founderTitle {
    font-size: 20.42px;
  }

  .founderDescription {
    font-size: 14.58px;
    padding: 8.75px;
  }
  .FounderLink {
    font-size: 20.42px;
    padding: 5.83px;
  }
}

@media screen and (max-width: 740px) {
  .Intro {
    min-width: 600px;
    min-height: 300px;
    max-width: 600px;
    max-height: 300px;
    margin-top: 100px;
  }
  .bshib,
  .shibShadow {
    height: 249.44px;
    width: 249.44px;
  }
  .shibShadow {
    margin: 22.685px;
  }
  .typerDiv {
    font-size: 22.685px;
    padding: 22.685px;
    margin-top: 22.685px;
  }
  .ShibaDown {
    min-width: 600px;
    min-height: 200px;
  }
  .founderDiv {
    min-width: 600px;
    min-height: 350px;
    max-width: 600px;
    max-height: 350px;
    margin-bottom: 29.16px;
  }
  .founderImage {
    min-width: 200px;
    min-height: 300px;
    max-width: 200px;
    max-height: 300px;
    margin-left: 25px;
  }

  .founderText {
    min-width: 320px;
    min-height: 300px;
    max-width: 320px;
    max-height: 300px;
  }

  .founderTitle {
    font-size: 17.5px;
  }

  .founderDescription {
    font-size: 12.49px;
    padding: 7.5px;
  }
  .FounderLink {
    font-size: 17.5px;
    padding: 4.85px;
  }
}

@media screen and (max-width: 645px) {
  .Intro {
    min-width: 500px;
    min-height: 250px;
    max-width: 500px;
    max-height: 250px;
    margin-top: 100px;
  }
  .bshib,
  .shibShadow {
    height: 207.88px;
    width: 207.88px;
  }
  .shibShadow {
    margin: 18.9px;
  }
  .typerDiv {
    font-size: 18.96px;
    padding: 18.9px;
    margin-top: 18.9px;
  }
  .ShibaDown {
    min-width: 500px;
    min-height: 166.66px;
  }
  .founderDiv {
    min-width: 500px;
    min-height: 291.69px;
    max-width: 500px;
    max-height: 291.69px;
    margin: 24.3px;
  }
  .founderImage {
    min-width: 166.68px;
    min-height: 250px;
    max-width: 166.68px;
    max-height: 250px;
    margin-left: 20.83px;
  }

  .founderText {
    min-width: 266.68px;
    min-height: 250px;
    max-width: 266.68px;
    max-height: 250px;
  }

  .founderTitle {
    font-size: 14.58px;
  }

  .founderDescription {
    font-size: 10.4px;
    padding: 6.25px;
  }
  .FounderLink {
    font-size: 14.58px;
    padding: 4.04px;
  }
}

@media screen and (max-width: 545px) {
  .Intro {
    min-width: 400px;
    min-height: 200px;
    max-width: 400px;
    max-height: 200px;
    margin-top: 100px;
  }
  .bshib,
  .shibShadow {
    height: 166.304px;
    width: 166.304px;
  }
  .shibShadow {
    margin: 15.12px;
  }
  .typerDiv {
    font-size: 15.12px;
    padding: 15.12px;
    margin-top: 15.12px;
  }
  .ShibaDown {
    min-width: 400px;
    min-height: 133.333px;
  }
  .founderDiv {
    min-width: 400px;
    min-height: 233.35px;
    max-width: 400px;
    max-height: 233.35px;
    margin: 19.44px;
  }
  .founderImage {
    min-width: 133.34px;
    min-height: 200px;
    max-width: 133.34px;
    max-height: 200px;
    margin-left: 16.66px;
  }

  .founderText {
    min-width: 213.34px;
    min-height: 200px;
    max-width: 213.34px;
    max-height: 200px;
  }

  .founderTitle {
    font-size: 11.664px;
  }

  .founderDescription {
    font-size: 8.32px;
    padding: 5px;
  }
  .FounderLink {
    font-size: 11.664px;
    padding: 3.03px;
  }
}
